import { Link } from "gatsby";
import React from "react";
import Map from "./Map";

const Footer = ({
  heading,
  list = [],
  mandatoryLink,
  linkText,
  paragraph1,
  paragraph2,
}) => {
  return (
    <div className="gnx__map-component-wrapper gnx__footer-wrapper pad-bottom-3rem w-90-wrapper fw-400 ">
      <div className="desktop-service-image map-title">
        <h2 className="semi-page-heading fw-700">{heading}</h2>
        <div className="red-underline"></div>
      </div>
      <div className="gnx__map-information-wrapper">
        <div className="left-info-wrapper">
          <Map />
          {list?.leftInformation?.map(({ title, paragraph }) => {
            return (
              <>
                <div className="desktop-service-image map-info-block">
                  {/* <h3 className="para  margin-0 fw-600">{title}</h3> */}
                  <h3 className="para  margin-0 fw-600">{title}</h3>
                  <p className="para  margin-0">{paragraph}</p>
                </div>
                <div className="mobile-service-image map-info-block">
                  <h3 className="para  margin-0 fw-600">{title}</h3>
                  <p className="para  margin-0">{paragraph}</p>
                </div>
              </>
            );
          })}
        </div>
        <div className="right-info-wrapper">
          {list?.rightInformation?.map(
            ({ title, paragraph, paragraph1, paragraph2 }, index) => {
              return (
                <div className="map-info-block fw-400">
                  <h3 className="para fw-600">{title}:</h3>
                  {paragraph1 ? (
                    <div className="">
                      <p className="para fw-400">{paragraph1}</p>
                      <p className="para fw-400 m-top-20px">{paragraph2}</p>
                    </div>
                  ) : (
                    <p className="para fw-400">{paragraph}</p>
                  )}
                  {index === list?.rightInformation?.length - 1 && (
                    <a
                      href={mandatoryLink}
                      target="_blank"
                      className="gnx-light-gray-text fw-400 "
                    >
                      {linkText}
                    </a>
                  )}
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default Footer;
